import React from "react";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";

class Page extends React.Component {
  render() {
    const css = {
      subTitle: "text-2xl mt-16 mb-8",
      sectionTitle: "text-xl mt-8 mb-4",
    };

    const Paragraph = ({ children }) => <p className="my-4">{children}</p>;

    return (
      <Base>
        <SEO title="Conditions Générales d'Utilisation" />

        <div className="pb-32">
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-3xl font-bold">
                Conditions Générales d'Utilisation du site omnidoc.fr
              </h1>
            </Container>
          </div>
          <Container>
            <div className="max-w-2xl">
              <h2 className={css.subTitle}>1. Préambule</h2>
              <Paragraph>
                {"Les présentes Conditions Générales d’Utilisation (ci-après « CGU ») sont conclues entre « Omnidoc.fr», " +
                  "site dédié à la mise en relation et à la réalisation de télé-expertises, et entre les personnes inscrites " +
                  "sur le site (ci-après les « Utilisateurs ») qui utilisent le site accessible notamment à l’adresse " +
                  "www.app.omnidoc.fr (ci après le « Site »)."}
                <br />
                <br />
                {"Le Site est édité par la société Omnidoc, SAS au capital social de 1000 euros dont le siège social est " +
                  "situé 12 rue Anselme, 93400 Saint-Ouen-sur-Seine, Paris, et est immatriculée au Registre du Commerce et des Sociétés de Paris " +
                  "sous le numéro 847 506 144."}
                <br />
                <br />
                {"Les données de santé à caractère personnel collectées et traitées dans le cadre de l’utilisation de " +
                  "la plateforme Omnidoc sont hébergées auprès d’un hébergeur agréé de santé, conformément aux " +
                  "dispositions de l’article L.1111-8 du Code de la santé publique. "}
                En particulier, les données de santé et les serveurs sont
                hébergés par : OVH (ci-après "l’Hébergeur").
                <br />
                <div className="italic">
                  Société par actions simplifiée au capital de 10.069.020 euros
                  <br />
                  Siège social : 2 rue Kellermann, 59100 Roubaix
                  <br />
                  RCS Lille 424 761 419
                </div>
                <br />
                <br />
                Les serveurs sont administrés par Claranet, infogéreur certifié
                pour l'hébergement et l'infogérance des données de santé
                (ci-après "l’Infogéreur").
                <br />
                <div className="italic">
                  Siège social : 2 rue Bréguet 75011 Paris
                  <br />
                  RCS de Paris B 419 632 286
                </div>
              </Paragraph>
              <h2 className={css.subTitle}>2. Fonctionnalités du site</h2>
              <Paragraph>
                Omnidoc.fr est une plateforme de collaboration entre Praticiens de santé.
                Le Site permet plus particulièrement la réalisation de
                téléexpertises médicales, telles que définies dans la loi
                Hôpital Patient Santé et Territoires (HPST) de juillet 2009, et
                selon les conditions précisées dans l’avenant 6 à la convention
                médicale de 2016.
                <br />
                <br />
                <div>
                  En particulier, le site assure :
                  <li className = "mt-4">L'identification des acteurs</li>
                  <li>La sécurité des données</li>
                  <li>La traçabilité des échanges</li>
                </div>
                <br />
                Les données sont hébergées par un hébergeur agréé de données de
                santé (OVH), et infogérées par un infogéreur certifié pour
                l'hébergement de données de santé (Claranet).
                <br />
                <br />
                Les données de santé collectées lors des téléexpertises ne sont
                accessibles qu’aux Praticiens de santé ayant participé à leur
                réalisation.
              </Paragraph>

              <h2 className={css.subTitle}>3. Accès au Site</h2>
              <Paragraph>
                L’accès au Site et son utilisation sont réservés aux Médecins
                inscrits à l’Ordre national des médecins et titulaire d’un
                numéro RPPS en cours de validité ainsi qu'aux Infirmiers
                titulaire d'un numéro RPPS. Cette validité est contrôlée
                par comparaison avec la base de données RPPS mise à disposition
                par l’ANS et mise à jour quotidiennement.
                <h3 className={css.sectionTitle}>
                  3.1 Conditions d’ouverture de compte sur le Site
                </h3>
                L’ouverture d’un compte Utilisateur sur le Site est subordonnée
                à la transmission du formulaire d’inscription dûment complété.
                Pour pouvoir bénéficier de tous les services du site, en
                particulier pour pouvoir demander ou répondre à une
                téléexpertise, l’utilisateur doit valider son profil, ce qu’il
                peut faire en utilisant les outils d’authentification de l’ANS
                (CPS ou eCPS) ou en soumettant une copie d’un justificatif
                (carte CPS ou carte d'identité) sur le site. Dans le premier
                cas, la validation est instantanée, dans le second, elle
                intervient dans les deux heures suivant la soumission (du lundi
                au vendredi de 9h à 19h).
                <br />
                En cas d’incohérence entre le formulaire transmis et la pièce
                justificative adressée, Omnidoc se réserve la possibilité de
                solliciter des informations complémentaires. Si les personnes
                souhaitant bénéficier du Site ne communiquent pas l’ensemble des
                informations demandées, ou si elles communiquent des
                informations inexactes et/ou incomplètes, leur inscription au
                Site ne sera pas validée et elles ne pourront pas utiliser les
                services mis à leur disposition sur le Site.
              </Paragraph>
              <h3 className={css.sectionTitle}>3.2. Acceptation des CGU</h3>
              <Paragraph>
                Dans le cadre de leur inscription au Site, les Utilisateurs
                prennent connaissance des présentes CGU et les acceptent
                formellement en poursuivant leur inscription. Omnidoc est
                susceptible de modifier unilatéralement les présentes CGU, ce
                dont elle informera en temps utile les Utilisateurs par tout
                moyen, notamment lors de leur identification et/ou connexion
                ultérieure au Site ou par email. A défaut d’acceptation des CGU
                modifiées, l’Utilisateur pourra voir son accès au Site suspendu.
              </Paragraph>
              <h2 className={css.subTitle}>
                4. Confidentialité et déontologie
              </h2>
              <Paragraph>
                Les mot de passe et identifiant de l’Utilisateur sont
                strictement personnels. L’utilisateur s’interdit de les
                communiquer à un tiers.
                <br />
                Dans le cas contraire, il supporte toutes les conséquences qui
                découleraient d’une utilisation du Site non conforme aux CGU de
                ses identifiant et mot de passe par une tierce personne.
                <br />
                En cas de divulgation involontaire, ou de présomption de
                divulgation involontaire, l’Utilisateur s’engage à prendre
                toutes précautions utiles et notamment à modifier sans délai son
                mot de passe via la rubrique prévue à cet effet et accessible
                sur le Site.
                <br />
                L’Utilisateur est soumis, dans ses relations avec ses patients
                et dans ses relations avec les autres Utilisateur, aux
                dispositions du Code de déontologie médicale et du Code de la
                santé. En particulier, l’Utilisateur s’interdit toute manœuvre
                constitutive d’une captation ou d’un détournement de clientèle.
              </Paragraph>
              <h2 className={css.subTitle}>5. Responsabilité </h2>
              <Paragraph>
                <Paragraph className={css.sectionTitle}>
                  5.1 Responsabilité du site
                </Paragraph>
                Omnidoc est tenu à une obligation de moyen pour la délivrance du
                service accessible via le Site.
                <br /><br />
                En particulier, Omnidoc n’est pas responsable en cas :
                <li className="mt-4">
                  de survenance d'un événement de force majeure ayant un impact
                  sur le service du Site ;
                </li>
                <li>de problèmes liés au réseau internet ;</li>
                <li>
                  de pannes ou dommages résultant des équipements de
                  l'Utilisateur ou encore de la contamination du système
                  informatique de l'Utilisateur par des virus, attaques et
                  malveillances de tiers ;
                </li>
                <li>
                  d'indisponibilité ou dysfonctionnement du Site quelle qu'en
                  soit la raison ;
                </li>
                <li>
                  d'utilisation du Site par l'Utilisateur non conforme aux
                  présentes CGU.
                </li>
                L'indisponibilité du service ne donne droit à aucune indemnité
                et la responsabilité d’Omnidoc ne saurait être engagée en cas de
                d'indisponibilité ou de dysfonctionnement du Site.
                <Paragraph className={css.sectionTitle}>
                  5.2. Responsabilité de l'Utilisateur
                </Paragraph>
                L'Utilisateur est seul responsable de l'utilisation du Site
                conformément à son usage, dans le respect des lois et règlements
                en vigueur et des présentes CGU. Ainsi, l'Utilisateur est seul
                responsable du contenu des messages échangés au moyen du Site.
                <br />
                Toute utilisation du Site préjudiciable au patient est
                susceptible d‘engager la responsabilité de l’Utilisateur.
                <br />
                Omnidoc ne contrôle pas le contenu des messages échangés entre
                les Utilisateurs. En conséquence, les Utilisateurs sont seuls
                responsables des messages échangés et du contenu des messages.
              </Paragraph>
              <h2 className={css.subTitle}>6. Télé-expertise médicales</h2>
              <Paragraph>
                Le Site permet de réaliser des télé-expertises médicales. Une
                télé-expertise est une demande d’avis à distance, entre
                confrères, au sujet d’un patient du requérant.
                <br />
                Les informations relatives aux patients ne peuvent pas être
                partagées avec d’autres professionnels de santé qui ne seraient
                pas Utilisateurs du Site. La consultation des télé-expertises
                médicales est réservée à l’Utilisateur qui requiert une
                télé-expertise, et à celui qui y répond. Par conséquent, le
                patient ne peut maîtriser le contenu où les accès à son dossier
                en dehors des cas prévus à l’article suivant.
              </Paragraph>
              <h2 className={css.subTitle}>
                7. Données à caractère personnel et secret médical
              </h2>
              <Paragraph className={css.sectionTitle}>
                7.1. Données à caractère personnel
              </Paragraph>
              <Paragraph>
                Omnidoc respecte la loi n°78-17 du 6 janvier 1978. Omnidoc est
                responsable du traitement des données à caractère personnel
                effectué sur le Site. Le Site permet l'échange de données à
                caractère personnel - dont des données de santé - relatives aux
                personnes physiques prises en charge par les Utilisateurs. En
                qualité de professionnel de santé et d’Utilisateur du Site, les
                données de santé du patient peuvent être partagées avec d’autres
                Utilisateur professionnel de santé, avec son accord préalable, à
                des fins de diagnostic. Il appartient à l'Utilisateur du Site de
                délivrer aux personnes concernées (les patients) les
                informations suivantes :
                <li className="mt-4">l'identité du responsable de traitement (Omnidoc) ;</li>
                <li>
                  la finalité du traitement consistant à la gestion des
                  informations et au partage de celle-ci dans un objectif de
                  diagnostic, d’information et d’amélioration des connaissances;
                </li>
                <li>
                  les modalités d'exercice des droits d'opposition, de
                  rectification, de suppression et d'accès aux données à
                  caractère personnel ;
                </li>
                <li>
                  les modalités d'hébergement des données à caractère personnel
                  échangées par Omnidoc (dont certaines relèvent de la catégorie
                  des données de santé à caractère personnel) auprès d'un
                  hébergeur agréé à cet effet par décision du ministre en charge
                  de la santé.
                </li>
                Les demandes d'accès aux données de santé sont traitées dans le
                respect des règles prévues à l’article L 1111-7 du Code de la
                santé publique. En conséquence, la personne concernée par les
                données échangées via Omnidoc peut s'adresser directement à
                l'Utilisateur ou au responsable de traitement. Les modalités
                d'exercice de ces droits sont précisées dans le document
                d’information proposé par Omnidoc à l’adresse suivante
                https://www.omnidoc.fr/CGU à la disposition de l'Utilisateur et
                à l’attention des patients.
                <br />
                Cette information pourra être délivrée par voie d’affichage dans
                le cabinet de l'Utilisateur, par remise en main propre d'un
                document d’information à l’attention des patients. Conformément
                aux articles 38 à 40 de la loi n°78-17 du 6 janvier 1978 toute
                personne concernée dispose d'un droit d'accès, d'un droit de
                rectifier, de compléter, de mettre à jour, de s'opposer ou
                d'effacer les données à caractère personnel la concernant.
                <br />
                Pour exercer ces droits, la personne concernée peut contacter
                par courrier électronique Omnidoc à l'adresse suivante :
                contact_rgpd@omnidoc.fr
                <br />
                Toute demande devra être accompagnée de la copie d’un titre
                d’identité portant la signature du titulaire. La demande devra
                préciser l’adresse postale à laquelle devra parvenir la réponse.
                Omnidoc disposera alors d’un délai de deux mois pour répondre
                suivant réception de la demande.
              </Paragraph>
              <Paragraph className={css.sectionTitle}>
                7.2. Consentement des patients
              </Paragraph>
              <Paragraph>
                Aucune donnée à caractère personnel n'est traitée sur le Site
                sans le consentement préalable de la personne concernée. A cette
                fin, l’Utilisateur s’engage à informer systématiquement ses
                patients de son inscription au Site et en particulier du fait
                que ses informations médicales peuvent être gérées, de manière
                sécurisée, via le Site. L’Utilisateur est responsable du recueil
                du consentement de ses patients. A la suite de cette
                information, l’Utilisateur s’engage à recueillir le consentement
                de son patien.
              </Paragraph>
              <Paragraph className={css.sectionTitle}>
                7.3. Secret médical
              </Paragraph>
              <Paragraph>
                Les données de santé à caractère personnel sont couvertes par le
                secret professionnel dans les conditions prévues à l'article L
                1110-4 du Code de la santé publique, dont la violation est
                réprimée par l'article 226-13 du Code pénal. La révélation
                d’informations à d’autres Utilisateurs ne peut intervenir
                qu’après accord du patient concerné.
              </Paragraph>
              <h2 className={css.subTitle}>8. Propriété intellectuelle</h2>
              <Paragraph>
                Omnidoc est titulaire des noms de domaine omnidoc.fr ainsi que
                de la marque Omnidoc.
                <br />
                Le Site dans son ensemble, ainsi que les éléments qui le
                composent (notamment, charte graphique, textes, arborescence,
                logiciels mis en œuvre pour le fonctionnement du Site,
                animations, bases de données, plateformes techniques,
                illustrations, schémas, représentations, logos, etc.)
                constituent des œuvres de l'esprit protégées par les articles
                L.111-1 et suivants du Code de la propriété intellectuelle.
                <br />
                Le Site ainsi que les éléments qui le composent sont la
                propriété exclusive d’Omnidoc, seule habilitée à utiliser les
                droits de propriété intellectuelle y afférents, notamment
                marques, modèles, droits d'auteur, droit sui generis de base de
                données, à titre originaire ou par l'effet d'une licence ou
                d'une autorisation expresse.
                <br />
                L'usage de tout ou partie du Site, notamment par extraction,
                téléchargement, reproduction, transmission, "reverse
                engineering", décompilation, désassemblage, représentation ou
                diffusion est strictement interdit. La violation de ces
                dispositions soumet son auteur aux sanctions prévues tant par le
                Code de la propriété intellectuelle au titre notamment de la
                contrefaçon de droits d'auteur (articles L.335-3 et suivants du
                Code de la propriété intellectuelle), de droit des marques
                (articles L.716-9 et suivants du Code de la propriété
                intellectuelle), de droit du producteur de bases de données
                (Article L341-1 et suivants du Code de la propriété
                intellectuelle) que par le Code civil en matière de
                responsabilité civile (articles 1382 et suivants du Code civil).
                <br />
                La création de tous liens hypertextes renvoyant à l'une
                quelconque des pages web ou éléments composant le Site ou les
                services proposés par Omnidoc est interdite, sauf autorisation
                écrite et préalable d’Omnidoc, laquelle autorisation pouvant
                être révoquée à tout moment.
                <br />
                Tous les sites ayant un lien hypertexte dirigeant vers le Site
                ou l'un quelconque des éléments qui le composent ne sont pas
                sous le contrôle d’Omnidoc et celle-ci décline par conséquent
                toute responsabilité (notamment éditoriale) concernant l'accès
                et le contenu de ces sites. L’Utilisateur s’engage à respecter
                pleinement l’intégralité des droits d’Omnidoc. A ce titre, il
                reconnaît qu'il n'est cessionnaire d'aucun autre droit que celui
                d'utiliser les moyens notamment techniques mis à sa disposition
                par le Site.
              </Paragraph>
              <h2 className={css.subTitle}>9. Obligations d’Omnidoc</h2>

              <Paragraph className={css.sectionTitle}>
                9.1. Sécurité des informations stockées
              </Paragraph>
              <Paragraph>
                Omnidoc a confié l’hébergement des données à la société
                Claranet, certifiée par l’Agence des Systèmes d’Information
                Partagés de Santé (ASIP Santé). L’Utilisateur est informé et
                accepte que l’historique des accès au Site soit utilisé comme
                preuve en cas de contestation sur la confidentialité ou
                l’intégrité des données. L’intégrité, la confidentialité et
                l’audibilité des informations stockées sur le Site est conforme
                aux exigences légales.
              </Paragraph>
              <Paragraph className={css.sectionTitle}>
                9.2. Niveau de service
              </Paragraph>
              <Paragraph>
                Le Site est accessible 7 jours sur 7 et 24 heures sur 24, à
                l'exception des cas de force majeure, difficultés liées à la
                structure du réseau de télécommunications ou difficultés
                techniques. Pour des raisons de maintenance, l’accès au Site
                peut être suspendu. Omnidoc fera tout son possible afin d'en
                informer préalablement les Utilisateurs. Le niveau de service
                peut être mesuré par les utilisateurs du Site via un tableau de
                bord mis à leur disposition.Omnidoc ne prend aucun engagement à
                fournir les services mis à disposition sur le Site de façon
                ininterrompue, ou pleinement compatibles avec les matériels et
                logiciels des Utilisateurs. Omnidoc n'engage pas sa
                responsabilité et ne pourra subir aucune conséquence du fait du
                fonctionnement éventuellement dégradé ou défectueux de son Site,
                de l'indisponibilité totale ou partielle, de tout ou partie de
                ses fonctionnalités. Omnidoc ne garantit pas la durée des
                services et fonctionnalités du Site et se réserve expressément
                le droit de mettre fin à toute(s) ou partie(s) de ses
                fonctionnalités, à tout moment, sans préavis et sans motif.
              </Paragraph>
              <Paragraph className={css.sectionTitle}>
                9.3. Absence de conseil médical
              </Paragraph>
              <Paragraph>
                Le Site et ses Utilisateurs ne fournissent pas de conseil
                médical. Les avis donnés par les Utilisateurs constituent des
                analyses et des recommandations fondées sur les seuls éléments
                partagés par un Utilisateur et uniquement à destination d’autres
                Utilisateurs. Par conséquent la mise en œuvre et le suivi de ces
                recommandations demeurent sous l’entière responsabilité du
                médecin en charge de son patient. Omnidoc ne saurait, à aucun
                titre et en aucune manière, voir sa responsabilité engagée,
                notamment dans le cas de toute difficulté contractuelle ou
                précontractuelle entre l’Utilisateur et le patient.
              </Paragraph>
              <h2 className={css.subTitle}>10. Dispositions diverses</h2>
              <Paragraph>
                Le service proposé par le Site est à destination des
                Utilisateurs Professionnels de santé exerçant en France. En conséquence,
                les présentes CGU régissent les relations entre les Utilisateurs
                du Site et la société Omnidoc et sont soumises en toutes leurs
                dispositions à la loi française. Tout litige sera soumis aux
                tribunaux compétents du ressort de la Cour d’appel de Paris.
              </Paragraph>
            </div>
            <div className="mt-12"><Paragraph>Dernière mise à jour : 23 avril 2021.</Paragraph></div>
          </Container>
        </div>
      </Base>
    );
  }
}

export default Page;
